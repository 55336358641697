@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.view-work-button{
  padding:20px;
}

.montserrat{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

.popup-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0); /* Start with transparent */
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out; /* Transition effect */
  visibility: hidden;
  opacity: 0;
  z-index: 50; /* Ensure it's above other content */
}

.popup-overlay.open {
  background: rgba(0, 0, 0, 0.9); /* Darken the background */
  visibility: visible;
  opacity: 1;
}


.popup-content {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  z-index: 51; /* Ensure it's above the overlay */
}
.popup-content.open {
  visibility: visible;
}

.popup-content-inner {
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 70px;
  background: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #000000; /* Background of the scrollbar track */
  border: 1px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #fbe205; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
  border: 3px solid #2c2f33; /* Padding around thumb */
}